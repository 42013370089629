/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  getUserDetails, showAllUsers, editUser,
} from '../../redux/users/userActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import EditUser from './UserDetailComponents/EditUser';
import Deposit from './UserDetailComponents/Deposit';
import WithdrawalComponent from './UserDetailComponents/WithdrawalComponent';
import ActiveOrder from './UserDetailComponents/ActiveOrder';
import ExchangeList from './UserDetailComponents/ExchangeList';
import TradeHistory from './UserDetailComponents/TradeHistory';
import Accounts from './UserDetailComponents/Accounts/Accounts';
import PSP from './UserDetailComponents/PSP/PSP';
import { UserActivity } from './UserActivity';
import { TradingSettings } from './UserDetailComponents/TradingSettings';
import { getLocalDataByCountryCode } from '../../components';
import { SavingInvestmentsDatatable } from '../../DataTableSchemas/SavingInvestmentsDatatable';
import { selectKycAmlRequests } from '../../redux/kycAml/kycAmlSelectors';
import { getKycAmlRequestsByUserId } from '../../redux/kycAml/kycAmlActions';
import UserDetailsKycAmlRequests from '../KycAml/UserDetailsKycAmlRequests/UserDetailsKycAmlRequests';
import { getIsUserInScope, setUserIsInScopeByType } from '../../redux/hierarchy/hierarchyActions';
import { GET_IS_USER_IN_SCOPE } from '../../redux/hierarchy/hierarchyTypes';
import socket from '../../services/socket';
import { MonthlyInactivityFee } from './UserDetailComponents/MonthlyInactivityFee/MonthlyInactivityFee';

function UserDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [user, setUser] = useState('');
  const [, setSelectedFlagCountry] = useState('');
  const [, setDateOfBirth] = useState('');
  const [loader, setLoader] = useState(false);
  const [activeTab, setActveTab] = useState('edit');
  const [selectedUserType, setSelectedUserType] = useState(1);

  const userData = useSelector((state) => state?.users?.user);
  const userEditted = useSelector((state) => state?.users?.userEditted);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const allUsersList = useSelector((state) => state?.users?.users);
  const kycAmlRequests = useSelector(selectKycAmlRequests);
  const { isUserInScope } = useSelector((state) => state.hierarchy);
  const [onlineUsers, setOnlineUsers] = useState({});

  const isUserCanSeeTradeSettings = permissionName && permissionName.includes('trade_management');
  const isUserCanSeeKyc = permissionName && permissionName.includes('kyc_aml');
  const isUserCanEditUser = permissionName && permissionName.includes('edit_user');
  const isUserCanSeeClients = permissionName && permissionName.includes('clients');
  const isUserCanSeeSaving = permissionName && permissionName.includes('saving_management');
  const isUserCanSeeAccounts = permissionName && permissionName.includes('cfd_accounts');
  const isUserCanSeeDeposits = permissionName && permissionName.includes('deposits');
  const isUserCanSeeWithdrawals = permissionName && permissionName.includes('withdrawals');
  const isUserCanSeeActivity = permissionName && permissionName.includes('clients_report');
  const isUserCanPromote = permissionName && permissionName.length > 0 && permissionName.includes('client_promote');
  const isUserCanDemote = permissionName && permissionName.length > 0 && permissionName.includes('client_demote');
  const isUserCanSeeOpenOrders = permissionName && permissionName.length > 0 && permissionName.includes('open_orders');
  const isUserCanSeeOrdersHistory = permissionName && permissionName.length > 0 && permissionName.includes('orders_history');
  const isUserCanSeePSP = permissionName && permissionName.length > 0 && permissionName.includes('user_psp');

  const userIndex = allUsersList.indexOf(allUsersList.filter((item) => item._id === user._id)[0]);
  const prevUser = userIndex > 0 ? allUsersList[userIndex - 1] : allUsersList[allUsersList.length - 1];
  const nextUser = userIndex < allUsersList.length - 1 ? allUsersList[userIndex + 1] : allUsersList[0];

  const onlineUserIds = Object.values(onlineUsers);

  useEffect(() => {
    setTimeout(() => {
      socket.emit('getOnlineCRMUsers');
    }, 1000);
    
    socket.on('recieveOnlineCRMUsers', ({ onlineUsers = {} }) => {
      setOnlineUsers(onlineUsers);
    });

    return () => {
      socket.off('recieveOnlineCRMUsers');
    };
  }, [socket]);

  useEffect(() => {
    if (user?._id) {
      dispatch(getKycAmlRequestsByUserId(user?._id));
    }
  }, [user?._id]);

  useEffect(() => {
    const savedTab = localStorage.getItem('user-details-tab');
    setActveTab(savedTab || 'edit');

    return () => {
      localStorage.setItem('user-details-tab', 'edit');
      dispatch(setUserIsInScopeByType({ type: GET_IS_USER_IN_SCOPE, value: undefined }));
    };
  }, []);

  useEffect(() => {
    dispatch(getIsUserInScope(id));
  }, [id]);

  useEffect(() => {
    const fetchData = () => {
      dispatch(getUserDetails(id));
    };

    if (isUserInScope !== undefined && isUserInScope) fetchData();
  }, [isUserInScope, id]);

  useEffect(() => {
    const fetchData = () => {
      const loginData = localStorage.getItem('user');
      const data = JSON.parse(loginData);
      const id = data?.roleId;
      const uid = data?._id;
      dispatch(showAllUsers('1', id, uid, userData.clientType));
    };
    if (!allUsersList.length) fetchData();
  }, [user]);

  useEffect(() => {
    if (userData.clientType && permissionName?.length) {
      if (isUserCanSeeClients) {
        setUser(userData);
        const { dateOfBirth, countryCode, clientType } = userData;
        if (dateOfBirth) setDateOfBirth(dateOfBirth);
        if (countryCode) setSelectedFlagCountry(countryCode);
        setSelectedUserType(clientType);
        setLoader(false);
      }
    }
  }, [userData, permissionName]);

  useEffect(() => {
    if (userEditted) {
      setLoader(false);
    }
  }, [userEditted]);

  const handleUserTypeChange = async () => {
    if (selectedUserType === 1) {
      const data = {
        clientType: 2,
      };
      await dispatch(editUser(user._id, data));
    } else {
      const data = {
        clientType: 1,
      };
      await dispatch(editUser(user._id, data));
    }
  };

  const padTo2Digits = (num) => num.toString().padStart(2, '0');

  const formatDate = (date) => {
    if (date) {
      return (`${[
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-')
      } ${
        [
          padTo2Digits(date.getHours()),
          padTo2Digits(date.getMinutes()),
          padTo2Digits(date.getSeconds()),
        ].join(':')}`);
    }
  };
  
  const setTabWithHistory = (tab) => {
    setActveTab(tab);
    localStorage.setItem('user-details-tab', tab);
  };
  
  return (
    loader ? (<FullPageTransparentLoader />) : (
      user ? (
        <div className="content-wrapper right-content-wrapper">
          <div className="content-box">
            <div className="crmuser-slider-block">
              <h5 style={{ color: (user && onlineUserIds.includes(user._id)) ? 'green' : 'white' }}>
                {user.firstName && user.lastName ? ` ${user.firstName} ${user.lastName} #${user.customId}` : ' Loading...'}
              </h5>
              {user.countryCode && (
                <span style={{ color: '#fff' }}>
                  {`${formatDate(getLocalDataByCountryCode(user?.countryCode))} ${user.balanceInUSD} USD (CREDIT: ${user.creditInUSD} USD)`}
                </span>
              )}
              <div style={{ display: 'flex', gap: '5px' }}>
                {user.clientType === 2 && isUserCanDemote && (
                  <div className="Domote-botton" onClick={() => handleUserTypeChange()}>
                    Demote to Lead
                  </div>
                )}

                {user.clientType === 1 && isUserCanPromote && (
                  <div className="Promote-botton" onClick={() => handleUserTypeChange()}>
                    Promote to Client
                  </div>
                )}
                <Link to={prevUser ? `/user-detail/${prevUser?._id}` : null}>
                  Previous
                </Link>
                <Link to={nextUser ? `/user-detail/${nextUser?._id}` : null}>
                  Next
                </Link>

              </div>
            </div>

            <div style={{ color: 'white', width: '100%' }}>
              <div className="user-details-tabs">
                <div
                  className={activeTab === 'edit' ? 'tab-active' : 'tab'}
                  onClick={() => setTabWithHistory('edit')}
                >
                  Personal
                </div>
                {isUserCanSeeAccounts && (
                  <div
                    className={activeTab === 'accounts' ? 'tab-active' : 'tab'}
                    onClick={() => setTabWithHistory('accounts')}
                  >
                    Accounts
                  </div>
                )}
                {isUserCanSeeDeposits && (
                  <div
                    className={activeTab === 'deposits' ? 'tab-active' : 'tab'}
                    onClick={() => setTabWithHistory('deposits')}
                  >
                    Deposits
                  </div>
                )}
                {isUserCanSeeWithdrawals && (
                  <div
                    className={activeTab === 'withdrawals' ? 'tab-active' : 'tab'}
                    onClick={() => setTabWithHistory('withdrawals')}
                  >
                    Withdrawals
                  </div>
                )}
                {isUserCanSeeOpenOrders && (
                  <div
                    className={activeTab === 'active-orders' ? 'tab-active' : 'tab'}
                    onClick={() => setTabWithHistory('active-orders')}
                  >
                    Open Orders
                  </div>
                )}
                {isUserCanSeeOrdersHistory && (
                  <div
                    className={activeTab === 'trade-history' ? 'tab-active' : 'tab'}
                    onClick={() => setTabWithHistory('trade-history')}
                  >
                    Orders History
                  </div>
                )}
                {isUserCanSeeActivity && (
                  <div
                    className={activeTab === 'user-activity' ? 'tab-active' : 'tab'}
                    onClick={() => setTabWithHistory('user-activity')}
                  >
                    Activity
                  </div>
                )}
                {/* <div
                className={activeTab === 'exchange-list' ? 'tab-active' : 'tab'}
                onClick={() => setActveTab('exchange-list')}
              >
                Exchange List
              </div> */}
                {isUserCanSeeSaving && (
                  <div
                    className={activeTab === 'saving-history' ? 'tab-active' : 'tab'}
                    onClick={() => setTabWithHistory('saving-history')}
                  >
                    Saving History
                  </div>
                )}
                {isUserCanSeeTradeSettings && (
                  <div
                    className={activeTab === 'trading-settings' ? 'tab-active' : 'tab'}
                    onClick={() => setTabWithHistory('trading-settings')}
                  >
                    Trading Settings
                  </div>
                )}
                {isUserCanSeeKyc && !!kycAmlRequests.length && (
                  <div
                    className={activeTab === 'kyc-aml' ? 'tab-active' : 'tab'}
                    onClick={() => setTabWithHistory('kyc-aml')}
                  >
                    Kyc/Aml
                  </div>
                )}
                {isUserCanSeePSP && (
                  <div
                    className={activeTab === 'psp' ? 'tab-active' : 'tab'}
                    onClick={() => setTabWithHistory('psp')}
                  >
                    PSP
                  </div>
                )}
                <div
                  className={activeTab === 'monthlyInactivityFee' ? 'tab-active' : 'tab'}
                  onClick={() => setTabWithHistory('monthlyInactivityFee')}
                >
                  Monthly Inactivity Fee
                </div>
              </div>
              <div>

                <br />
                {isUserCanEditUser && (
                  (activeTab === 'edit' && <EditUser />)
                  || (activeTab === 'accounts' && isUserCanSeeAccounts && <Accounts userId={user?._id} />)
                  || (activeTab === 'deposits' && isUserCanSeeDeposits && <Deposit />)
                  || (activeTab === 'withdrawals' && isUserCanSeeWithdrawals && <WithdrawalComponent />)
                  || (activeTab === 'active-orders' && isUserCanSeeOpenOrders && <ActiveOrder />)
                  // || (activeTab === 'exchange-list' && <ExchangeList />)
                  || (activeTab === 'trade-history' && isUserCanSeeOrdersHistory && <TradeHistory />)
                  || (activeTab === 'user-activity' && isUserCanSeeActivity && <UserActivity />)
                  || (activeTab === 'saving-history' && isUserCanSeeSaving && <SavingInvestmentsDatatable userId={user?._id} />)
                  || (activeTab === 'trading-settings' && isUserCanSeeTradeSettings && <TradingSettings />)
                  || (activeTab === 'kyc-aml' && isUserCanSeeKyc && <UserDetailsKycAmlRequests userId={user?._id} />)
                  || (activeTab === 'psp' && isUserCanSeePSP && <PSP />)
                  || (activeTab === 'monthlyInactivityFee' && <MonthlyInactivityFee />)
                )}
              </div>
            </div>

          </div>
        </div>
      ) : (isUserInScope === false) ? (
        <div className="content-wrapper right-content-wrapper">
          <div className="content-box">
            <div className="tab-pane fade show active tab-main-box" id="home" role="tabpanel" aria-labelledby="home-tab">
              <h3 className="text-capitalize">User not found</h3>
            </div>
          </div>
        </div>
      ) : null
    ) 
  );
}

export default UserDetail;
