import React, {
  useState, useMemo, useContext, 
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClone, faComment, faEnvelope, faPhone, faExternalLink, faSort,
  faSms,
} from '@fortawesome/free-solid-svg-icons';
import * as Flags from 'react-flags-select';
import { toast } from 'react-toastify';
import { BsTrashFill } from 'react-icons/bs';
import { editUser } from '../redux/users/userActions';
import { ModalFilters, getLocalDataByCountryCode } from '../components';
import { dateOptions } from './helper';
import { ENV } from '../config/config';
import { FIND_ONE_USER_AND_EDIT_IN_USERS } from '../redux/users/userTypes';
import { createUserStatusChangesHistory } from '../redux/userStatusChangesHistory/userStatusChangesHistoryActions';
import { apiHelper } from '../redux/apiHelper';
import SendSmtpEmailModal from '../pages/Users/UserDetailComponents/SendSmtpEmailModal/SendSmtpEmailModal';
import { ModalContext } from '../context';
import SendSmsModal from '../pages/Users/UserDetailComponents/SendSmsModal/SendSmsModal';

const currencyFormatter = require('currency-formatter');

export const UsersSchema = (
  permissionName,
  formatDate,
  allAffiliates,
  affiliateFilter,
  setAffiliateFilter,
  salesStatuses,
  countriesToShow,
  userAction,
  isCheckStatus,
  setIsCheckStatus,
  handleStatusChange2,
  assignToShow,
  isCheckAssignTo,
  deleteAction,
  clientFullname,
  searchByName,
  clientEmail,
  searchByEmail,
  clientPhone,
  searchByPhone,
  isCheckCountry, 
  setIsCheckCountry,
  setIsCheckAssignTo,
  columnConfig,
  usersFilters,
  setUserFilters,
  setTimeInitiatedFilter,
  timeInitiatedFilter,
  lastCommentDate,
  setLastCommentDate,
  brands,
  selectedBrands,
  setSelectedBrands,
  currencyRates,
  addCommentHandler = () => {},
  handleSort,
  onlineUsers,
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const CRMUser = useSelector((state) => state.crmUser.crmUserInfo);
  const users = useSelector((state) => state.users.users);
  const apiKey = process.env.REACT_APP_VOISO_API_KEY;
  const { currentUserPermissions } = useSelector((state) => state.crmUser);
  const isUserCanSeeComments = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('comments');

  const [showComment, setShowComment] = useState('');
  const [direction, setDirection] = useState('asc');
  const onlineUserIds = Object.values(onlineUsers);

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };
  const titleCase = (string) => string[0].toUpperCase() + string.slice(1).toLowerCase();
  const timePassed = (date) => {
    let status = '';
    if (date) {
      const first = new Date(date);
      const end = new Date(Date.now());
      const elapsed = (end - first);
      const CalcElapsed = elapsed / 1000;
      if (CalcElapsed > 0 && CalcElapsed < 50) {
        status = 'Online';
      } else {
        status = 'Offline';
      }
      return status;
    }
    return status;
  };

  const handleShowComment = (rowId) => {
    setShowComment(rowId);
  };

  const handleDropdownChange = async (data, id) => {
    try {
      dispatch(editUser(id, data));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChangeAssignTo = async (e, id) => {
    try {
      const data = {
        assignedTo: e.target.value,
      };
      await dispatch(editUser(id, data));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleStatusChange = async (e, id) => {
    try {
      const data = {
        salesStatusId: e.target.value,
      };
      await dispatch(editUser(id, data));

      const noStatusMock = { _id: '', name: 'Select Status', color: '#fff' };
      const previousStatus = users.find((user) => user._id === id)?.salesStatus ?? noStatusMock;
      const newSalesStatus = salesStatuses.find((status) => status._id === data.salesStatusId) ?? noStatusMock;

      if (previousStatus?._id !== newSalesStatus?._id) {
        dispatch(createUserStatusChangesHistory({
          user: id,
          agent: CRMUser._id,
          agentName: `${CRMUser.firstName} ${CRMUser.lastName}`,
          previousStatus: previousStatus.name,
          actualStatus: newSalesStatus.name,
        }));
      }

      dispatch({
        type: FIND_ONE_USER_AND_EDIT_IN_USERS,
        payload: {
          userId: id,
          ...data,
          salesStatus: newSalesStatus,
        },
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const loginAsUser = (user) => {
    if (user?._id) {
      window.open(`${process.env.REACT_APP_SERVER_URL}/login?email=${user?.email}&userByAdmin=${1}`, '_blank');
    }
  };

  const omitColumn = (columnName) => {
    if (columnName === 'Brand' && !ENV.hasMultibrand) return true;

    let omit = false;

    columnConfig.forEach((column) => {
      if (column.name === columnName) {
        omit = !column.selected;
      }
    });

    return omit;
  };

  // const handleClickToCall = async (phone) => {
  //   const agent = CRMUser?.voipToken;

  //   try {
  //     await axios.get(`https://cc-ams03.voiso.com/api/v1/${apiKey}/click2call?agent=${agent}&number=${phone}`);
  //   } catch (error) {
  //     console.error('Error initiating click-to-call:', error);
  //   }
  // };


  const handleClickToCall = async ({ id }) => {
    try {
      const res = await apiHelper('post', `/api/voip/call/${id}`);
      if (res && res.data && res.data.success) {
        const { message } = res.data;
  
        toast.success(message);
      }
    } catch (error) {
      console.log(error.message);
  
      toast.error("Error: cant't make call");
    }
  };

  const handleSendEmailBtn = (userId, showModal, hideModal) => {
    showModal({
      headerContent: <h3>SEND EMAIL</h3>,
      bodyContent: (
        <SendSmtpEmailModal
          userId={userId}
          handleClose={hideModal}
        />
      ),
    });
  };

  const handleSendSmsBtn = (user, showModal, hideModal) => {
    showModal({
      headerContent: (
        <h3>
          Send SMS to 
          {' '}
          {`${user?.firstName} ${user?.lastName}`} 
        </h3>
      ),
      bodyContent: <SendSmsModal
        userId={user._id}
        handleClose={hideModal}
        toPhoneNumber={user?.phone}
      />,
    });
  };

  const sortFields = (fieldName) => {
    handleSort(fieldName, direction);
    setDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
  };

  const isAffiliateRule = useMemo(() => (permissionName && permissionName.length && permissionName.includes('affiliates')), [permissionName]);

  const columns = useMemo(() => [
    {
      name: 'ID',
      minWidth: '150px',
      omit: omitColumn('ID'), 
      cell: ({ customId }) => (
        <CopyToClipboard text={customId}>
          <span style={{ margin: '5px' }}>
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
            {customId}
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: 'Actions',
      minWidth: '225px',
      omit: omitColumn('Full Name'),
      cell: (row) => {
        const {
          adminComments, _id, phone, 
        } = row;
        const commentDate = !adminComments?.length || formatDate(new Date(adminComments[0].createdAt));
        const { showModal, hideModal } = useContext(ModalContext);
        return (
          <div style={{
            width: '100%',
            paddingLeft: '30px',
            display: 'flex',
            gap: '3px',
            position: 'relative',
            justifyContent: 'flex-start',
          }}
          >
            {_id === showComment && (
              <div className="depositsAdditionalInfoModal" style={{ width: '200px', left: 50, top: -100 }}>
                <h6>Comments</h6>
                <br />
                <br />
                {adminComments?.length ? (
                  <>
                    {`Date: [${commentDate}]`}
                    <br />
                    <br />
                    {`Comment: ${!adminComments?.length || adminComments[0]?.text}`}
                  </>
                ) : (
                  'No Comments'
                )}
              </div>
            )}
  
            {isUserCanSeeComments && (
              <button
                type="button"
                className="datatable-action__btn"
                onClick={() => addCommentHandler(row)}
                onMouseEnter={() => handleShowComment(_id)} 
                onMouseLeave={() => setShowComment('')}
              >
                <FontAwesomeIcon icon={faComment} size="sm" />
              </button>
            )}
            <button
              type="button"
              className="datatable-action__btn"
              onClick={() => handleSendSmsBtn(row, showModal, hideModal)}
            >
              <FontAwesomeIcon icon={faSms} size="sm" />
            </button>
            <button
              type="button"
              className="datatable-action__btn"
              onClick={() => handleSendEmailBtn(_id, showModal, hideModal)}
            >
              <FontAwesomeIcon icon={faEnvelope} size="sm" />
            </button>
            <button
              type="button"
              className="datatable-action__btn"
              onClick={() => handleClickToCall({ id: _id })}
            >
              <FontAwesomeIcon icon={faPhone} size="sm" />
            </button>
            <Link 
              to={`/user-detail/${_id}`} 
              target="blank" 
              className="datatable-action__btn"
            >
              <FontAwesomeIcon icon={faExternalLink} size="sm" />
            </Link>
          </div>
        );
      },
    },
    {
      name: (
        <>
          <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
            Full Name
            <input
              className="leads-search-bar-filter"
              type="text"
              placeholder="Search"
              autoComplete="off"
              name="clientFullname"
              value={clientFullname}
              minLength="3"
              onChange={(e) => searchByName(e)}
            />
            {
            (clientFullname.length >= 1 && clientFullname.length < 3) && (
            <span className="datatable-input__error-text clr-red">Please enter atleast 3 charcater</span>
            )  
          }
          </div>
          <button type="button" className="sort-btn" onClick={() => sortFields('firstName')}>
            <FontAwesomeIcon icon={faSort} />
          </button>
        </>

      ),
      width: '250px',
      omit: omitColumn('Full Name'), 
      sortField: 'firstName',
      selector: (row) => `${row.firstName} ${row.lastName}`,
      cell: (row) => {
        const {
          _id, firstName, lastName, 
        } = row;
        return (
          <div style={{
            width: '100%',
            paddingLeft: '30px',
            display: 'flex',
            gap: '3px',
            position: 'relative',
            justifyContent: 'flex-start',
          }}
          >
            <Link to={`/user-detail/${_id}`}>
              <span className="full-name-text" style={{ color: onlineUserIds.includes(_id) ? 'green' : 'white' }}>
                {firstName}
                &nbsp;
                {lastName}
              </span>
            </Link>
          </div>
        );
      },
      // sortable: true,
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          Email
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="clientFullname"
            autoComplete="off"
            value={clientEmail}
            onChange={(e) => searchByEmail(e)}
          />
          {
            (clientEmail.length >= 1 && clientEmail.length < 3) && (
            <span className="datatable-input__error-text clr-red">Please enter atleast 3 charcater</span>
            )  
          }
        </div>
      ),
      width: '250px',
      omit: omitColumn('Email'), 
      selector: (row) => (

        permissionName && permissionName.length > 0 && permissionName.includes('user_email')
          ? (
            <div style={{ width: '250px', paddingLeft: '40px', marginRight: '10px' }}>
              <CopyToClipboard text={row.email}>
                <span style={{ margin: '5px' }} data-tip={row.email}>
                  <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                  {row.email}
                </span>
              </CopyToClipboard>
            </div>
          )
          : null

      ),
      sortable: false,
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          Phone Number
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="clientFullname"
            autoComplete="off"
            value={clientPhone}
            onChange={(e) => searchByPhone(e)}
          />
          {
            (clientPhone.length >= 1 && clientPhone.length < 3) && (
            <span className="datatable-input__error-text clr-red">Please enter atleast 3 charcater</span>
            )  
          }
        </div>
      ),
      width: '200px',
      omit: omitColumn('Phone Number'), 
      selector: (row) => (
        permissionName && permissionName.length > 0 && permissionName.includes('user_phone')
          ? (
            <div style={{ width: '200px', paddingLeft: '20px' }}>
              <CopyToClipboard text={row.phone}>
                <span style={{ margin: '5px' }} data-tip={row.phone}>
                  <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
                  {row.phone}
                </span>
              </CopyToClipboard>
            </div>
          )
          : null
      ),
      sortable: false,
    },
    {
      name: (
        <ModalFilters
          data={countriesToShow} 
          filters={isCheckCountry} 
          setFilters={setIsCheckCountry} 
          tabName="Country" 
          searchParams={{ id: 'iso', params: ['nicename', 'iso', 'iso3', 'name'], optionsName: ['nicename'] }}
          usersFilters={usersFilters}
          setUserFilters={setUserFilters}
        />
      ),
      width: '200px',
      omit: omitColumn('Country'),
      selector: (row) => {
        if (row.countryCode && row.country?.[0]?.nicename) {
          const flagTitleCase = titleCase(row.countryCode);
          const UserCountryFlag = Flags[flagTitleCase];

          return (
            <>
              <span style={{ fontSize: 18 }} className="me-1">{ UserCountryFlag && <UserCountryFlag /> }</span>
              <span>{row.country?.[0]?.nicename}</span>
            </>
          );
        }
        return '-';
      },
    },
    {
      name: (
        <>
          <ModalFilters 
            onClick={(e) => e.stopPropagation()}
            data={dateOptions} 
            filters={timeInitiatedFilter} 
            setFilters={setTimeInitiatedFilter} 
            tabName="Created" 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
            isDateOptions
          />
          <button type="button" className="sort-btn" onClick={() => sortFields('createdAt')}>
            <FontAwesomeIcon icon={faSort} />
          </button>
        </>

      ),
      width: '200px',
      omit: omitColumn('Created'),
      selector: (row) => (
        <span>{formatDate(new Date(row.createdAt))}</span>
      ),
      sortField: 'createdAt',
    },
    {
      name: (
        <ModalFilters 
          data={allAffiliates} 
          filters={affiliateFilter} 
          setFilters={setAffiliateFilter} 
          tabName="Affiliate Name" 
          searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
          usersFilters={usersFilters}
          setUserFilters={setUserFilters}
        />
      ),
      width: '250px',
      selector: (row) => {
        if (row.affiliator) return `${row.affiliator?.[0]?.firstName} ${row.affiliator?.[0]?.lastName}`;
        return '-';
      },
      cell: (row) => {
        const affiliator = row.affiliator?.[0] ? row.affiliator?.[0] : '';
        return (
          affiliator
            ? (
              <>
                <div className="truncate-text" data-for={`aff-${row._id}`} data-tip={`${affiliator?.firstName} ${affiliator?.lastName}`}>
                  {affiliator?.firstName}
                  {' '}
                  {affiliator?.lastName}
                </div>
                {/* <ReactTooltip id={`aff-${row._id}`} /> */}
              </>
            ) : '-'
        );
      },
      omit: omitColumn('Affiliate Name') || !isAffiliateRule,
      minWidth: '200px',
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          Funnel Name
        </div>
      ),
      minWidth: '150px',
      omit: omitColumn('Funnel Name'), 
      selector: (row) => (
        <div>
          <div className="truncate-text" style={{ margin: '5px' }} data-for={`funnel-${row._id}`} data-tip={row.source}>
            {row.source ? row.source : '-'}
          </div>
          {/* <ReactTooltip id={`funnel-${row._id}`} /> */}
        </div>
      ),
      sortable: false,
    },
    {
      name: (
        <ModalFilters 
          data={assignToShow} 
          filters={isCheckAssignTo} 
          setFilters={setIsCheckAssignTo} 
          tabName="Assigned To" 
          searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
          usersFilters={usersFilters}
          setUserFilters={setUserFilters}
        />
      ),
      width: '200px',
      selector: (row) => {
        if (row.assignedToAgent) return `${row.assignedToAgent?.[0]?.firstName} ${row.assignedToAgent?.[0]?.lastName}`;
        return '-';
      },
      cell: (row) => {
        const assignedToAgent = row.assignedToAgent?.[0] ? row.assignedToAgent?.[0] : null;
        const color = assignedToAgent ? assignedToAgent?.color : '#fff';

        if (permissionName && permissionName.length > 0 && permissionName.includes('assign_to_agent')) {
          return (
            <select 
              className="form-control user-status-select leads-status-select text-color-options-white"
              name="type"
              defaultValue={assignedToAgent ? assignedToAgent?._id : ''}
              style={{ color }}
              onChange={(e) => handleChangeAssignTo(e, row._id)}
            >
              <option value="" style={{ color: '#fff' }} color="#fff">Select Manager</option>
              {assignedToAgent && <option value={assignedToAgent._id} style={{ color: '#fff' }} color="#fff">{`${assignedToAgent.firstName} ${assignedToAgent.lastName}`}</option>}
              {assignToShow?.length > 0 && assignToShow.map((item) => {
                if (item._id !== assignedToAgent?._id) {
                  return (
                    <option key={item._id} value={item._id}>{`${item.firstName} ${item.lastName}`}</option>
                  ); 
                }
                return null;
              }) }
            </select>
          );
        }
        return assignedToAgent ? <div>{`${assignedToAgent.firstName} ${assignedToAgent.lastName}`}</div> : <div>-</div>;
      },
      omit: omitColumn('Assigned To'),
    },
    {
      name: (
        <ModalFilters 
          data={salesStatuses} 
          filters={isCheckStatus} 
          setFilters={setIsCheckStatus} 
          tabName="Status" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={usersFilters}
          setUserFilters={setUserFilters}
        />
      ),
      minWidth: '190px',
      omit: omitColumn('Status'),
      selector: (row) => (row.salesStatusId ? row.salesStatusId : ''),
      cell: (row) => {
        let currSalesStatusType = { _id: '', name: 'Select Status', color: '#fff' };
        if (row.salesStatusId) {
          currSalesStatusType = salesStatuses.find((stat) => stat._id === row.salesStatusId);
        }
        return (
          <select 
            className="form-control user-status-select leads-status-select"
            name="status"
            value={currSalesStatusType?._id}
            onChange={(e) => handleStatusChange(e, row._id)}
            style={{ color: currSalesStatusType?.color ? currSalesStatusType?.color : '#fff' }}
          >
            <option value="" style={{ color: '#fff' }} color="#fff">Select Status</option>
            {salesStatuses?.length && salesStatuses.map((currentStatus) => (
              <option value={currentStatus._id} key={currentStatus._id} style={{ color: currentStatus.color }} color={currentStatus.color}>
                {' '}
                {currentStatus.name}
              </option>
            ))}
          </select>
        );
      },
    },
    {
      name: (
        <ModalFilters 
          data={brands} 
          filters={selectedBrands} 
          setFilters={setSelectedBrands} 
          tabName="Brand" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={usersFilters}
          setUserFilters={setUserFilters}
        />
      ),
      minWidth: '190px',
      omit: omitColumn('Brand'), 
      cell: ({ brandId, _id }) => (
        !!brands.length && (
        <select 
          className="form-control user-status-select leads-status-select text-white"
          name="brand"
          defaultValue={brandId || ''}
          onChange={({ target: { value } }) => handleDropdownChange({ brandId: value }, _id)}
        >
          <option value="" className="text-white">-</option>
          {brands.length && brands.map((brand) => (
            <option value={brand._id} key={brand._id}>
              {brand.name}
            </option>
          ))}
        </select>
        )
      ),
    },
    {
      name: (
        <>
          <span>Balance</span>
          <button type="button" className="sort-btn" onClick={() => sortFields('totalBalance')}>
            <FontAwesomeIcon icon={faSort} />
          </button>
        </>
      ),
      omit: omitColumn('Balance'),
      minWidth: '120px',
      cell: ({ balanceInUSD }) => (
        <span>
          {currencyFormatter.format(balanceInUSD, { code: 'USD' })}
        </span>
      ),
      sortField: 'totalBalance',
    },
    {
      name: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>Credit</span>
          <button type="button" className="sort-btn" onClick={() => sortFields('totalCredit')}>
            <FontAwesomeIcon icon={faSort} />
          </button>
        </div>
      ),
      omit: omitColumn('Credit'),
      minWidth: '120px',
      cell: ({ creditInUSD }) => <span>{currencyFormatter.format(creditInUSD, { code: 'USD' })}</span>,
      sortField: 'totalCredit',
    },
    {
      name: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>Net Deposits</span>
          <button type="button" className="sort-btn" onClick={() => sortFields('totalDeposits')}>
            <FontAwesomeIcon icon={faSort} />
          </button>
        </div>
      ),
      omit: omitColumn('Net Deposits'),
      minWidth: '150px',
      cell: ({ netDeposits }) => <span>{currencyFormatter.format(netDeposits, { code: 'USD' })}</span>,
      sortField: 'totalDeposits',
    },
    {
      name: 'Online',
      omit: omitColumn('Online'),
      selector: ({ _id }) => (
        onlineUserIds.includes(_id)
          ? <span className="online">Online</span>
          : <span className="offline">Offline</span>
      ),
      sortable: false,
    },
    {
      name: (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span>Last Login Date</span>
          <button type="button" className="sort-btn" onClick={() => sortFields('lastLoginAt')}>
            <FontAwesomeIcon icon={faSort} />
          </button>
        </div>
      
      ),
      omit: omitColumn('Last Login Date'),
      selector: (row) => (
        <span>{row.lastLoginAt ? formatDate(new Date(row.lastLoginAt)) : '-'}</span>
      ),
      width: '200px',
      sortable: false,
    },
    {
      name: (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ModalFilters 
            data={dateOptions} 
            filters={lastCommentDate} 
            setFilters={setLastCommentDate} 
            tabName="Last Comment" 
            searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
            usersFilters={usersFilters}
            setUserFilters={setUserFilters}
          />
          <button type="button" className="sort-btn" onClick={() => sortFields('lastCommentAt')}>
            <FontAwesomeIcon icon={faSort} />
          </button>
        </div>
      ),
      cell: ({ adminComments = [] }) => (
        <span>{adminComments.length ? formatDate(new Date(adminComments[0].createdAt)) : '-'}</span>
      ),
      width: '250px',
      omit: omitColumn('Last Comment'),
    },
    {
      name: 'Local Time',
      omit: omitColumn('Local Time'),
      sortable: false,
      width: '180px',
      selector: (row) => (
        <span>{row.countryCode ? formatDate(getLocalDataByCountryCode(row.countryCode) || new Date()) : '-'}</span>
      ),
    },
    {
      name: 'Delete User',
      omit: omitColumn('Delete User'),
      width: '150px',
      cell: (row) => (
        <>
          {(permissionName && permissionName.length) > 0 && permissionName.includes('delete_user')
          && (
            <button type="button" className="btn btn-danger btn-sm me-1 p-1" onClick={() => deleteAction(row._id)}>
              <BsTrashFill role="button" color="white" size={18} />
            </button>
          )}
          {(permissionName && permissionName.length) > 0 && permissionName.includes('block_user')
            && (
              row.status === true
                ? <button type="button" className="btn btn-warning btn-sm me-1 p-1" onClick={() => userAction(row._id, 'block')}>Block</button>
                : <button type="button" className="btn btn-warning btn-sm me-1 p-1" onClick={() => userAction(row._id, 'unBlock')}>Unblock</button>
            )}
        </>
      ),
      sortable: false,
    },
  ], [
    clientFullname, 
    clientEmail, 
    clientPhone, 
    countriesToShow, 
    isCheckCountry,
    timeInitiatedFilter, 
    lastCommentDate,
    allAffiliates, 
    affiliateFilter, 
    isCheckAssignTo, 
    assignToShow,
    salesStatuses,
    isCheckStatus,
    usersFilters,
    omitColumn,
    showComment,
    dateOptions,
    isAffiliateRule,
  ]);

  return columns;
};
