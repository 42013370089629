import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faUndo } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { ModalFilters } from '../components';
import { omitColumn } from './helper';
import { ModalContext } from '../context';
import { EditOrderModal } from '../pages/OrdersHistory/EditOrderModal';
import { toFixed } from '../helpers/utils';

export const OrdersHistoryColumn = ({
  columnConfig,
  filters,
  setFilters,
  tradingTypeFilter,
  setTradingTypeFilter,
  coinsPairs,
  coinPairsFilter,
  setCoinPairsFilter,
  permissionName,
  handleRevertTradeHistory,
  userNameValue,
  handleChangeUserName,
  userId = '', 
}) => {
  const { showModal, hideModal } = useContext(ModalContext);

  const isUserCanSeeClientCard = permissionName.includes('clients');
  const isUserCanEditOrdersHistory = permissionName && permissionName.length > 0 && permissionName.includes('orders_history_edit');
  const isUserCanRevertOrdersHistory = permissionName && permissionName.length > 0 && permissionName.includes('orders_history_revert');

  const columns = [
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          User
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="userName"
            value={userNameValue}
            minLength="3"
            onChange={({ target: { value } }) => handleChangeUserName(value)}
          />
          {
            (userNameValue.length >= 1 && userNameValue.length < 3) && (
            <span className="datatable-input__error-text clr-red">Please enter at least 3 character</span>
            )  
          }
        </div>
      ),
      selector: (row) => (
        (isUserCanSeeClientCard) ? (
          <Link
            to={`/user-detail/${row?.user?._id}`}
            className="text-decoration-none text-white"
          >
            {`${row.user.firstName} ${row.user.lastName}`}
          </Link>
        ) : `${row.user.firstName} ${row.user.lastName}`
      ),
      omit: !!userId.length || omitColumn(columnConfig, 'User'),
      minWidth: '250px',
    },
    {
      name: 'ID',
      cell: ({ customId }) => customId,
      selector: () => 'customId',
      minWidth: '150px',
      sortable: true,
      omit: omitColumn(columnConfig, 'ID'),
    },
    {
      name: (
        <ModalFilters
          data={coinsPairs}
          filters={coinPairsFilter}
          setFilters={setCoinPairsFilter}
          tabName="Trading Pair" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => (row.primary || 0) + (row.secondary || 0),
      omit: omitColumn(columnConfig, 'Trading Pair'),
      width: '200px',
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Buy', _id: '1' }, { name: 'Sell', _id: '0' }]}
          filters={tradingTypeFilter}
          setFilters={setTradingTypeFilter}
          tabName="Trading Type" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => (row?.tradeType === 1 ? 'Buy' : 'Sell'),
      omit: omitColumn(columnConfig, 'Trading Type'),
      width: '200px',
    },
    {
      name: 'Price Start',
      cell: (row) => parseFloat(row.tradeStartPrice).toFixed(4),
      selector: () => 'tradeStartPrice',
      minWidth: '150px',
      sortable: true,
      omit: omitColumn(columnConfig, 'Price Start'),
    },
    {
      name: 'Volume',
      cell: ({ position }) => toFixed(position, 3),
      selector: () => 'position',
      minWidth: '150px',
      omit: omitColumn(columnConfig, 'Volume'),
      sortable: true,
    },
    {
      name: 'Price End',
      cell: (row) => parseFloat(row.tradeEndPrice).toFixed(4),
      selector: () => 'tradeEndPrice',
      minWidth: '150px',
      sortable: true,
      omit: omitColumn(columnConfig, 'Price End'),
    },
    {
      name: 'Margin',
      cell: ({ userInvestedAmount }) => parseFloat(userInvestedAmount).toFixed(2),
      selector: () => 'userInvestedAmount',
      minWidth: '150px',
      omit: omitColumn(columnConfig, 'Margin'),
      sortable: true,
    },
    {
      name: 'Profit/Loss',
      cell: (row) => parseFloat(row.exitPrice).toFixed(2),
      selector: () => 'exitPrice',
      minWidth: '150px',
      sortable: true,
      omit: omitColumn(columnConfig, 'Profit/Loss'),
    },
    {
      name: 'Swap',
      cell: ({ endSwap }) => (endSwap ? `-${endSwap}` : 0),
      selector: () => 'endSwap',
      sortable: true,
      omit: omitColumn(columnConfig, 'Swap'),
    },
    {
      name: 'Commission',
      cell: ({ endCommission }) => (endCommission ? `-${endCommission}` : 0),
      selector: () => 'endCommission',
      minWidth: '150px',
      sortable: true,
      omit: omitColumn(columnConfig, 'Commission'),
    },
    {
      name: 'Start Time',
      cell: (row) => moment(row.timeOpened).format('YYYY-MM-DD HH:mm:ss'),
      selector: () => 'createdAt',
      minWidth: '180px',
      sortable: true,
      omit: omitColumn(columnConfig, 'Start Time'),
    },
    {
      name: 'End Time',
      cell: (row) => moment(row.closedAt).format('YYYY-MM-DD HH:mm:ss'),
      selector: () => 'closedAt',
      minWidth: '180px',
      sortable: true,
      omit: omitColumn(columnConfig, 'End Time'),
    },
    {
      name: 'Action',
      selector: (row) => (
        row.isResolved !== false
          ? (
            <>
              {isUserCanEditOrdersHistory && (
                <button
                  type="button"
                  className="btn btn-success btn-sm me-1 p-1"
                  onClick={() => {
                    showModal({
                      headerContent: <h3>Edit Order</h3>,
                      bodyContent: <EditOrderModal order={row} hideModal={hideModal} />,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faPencil} className="header-icon text-white" />
                </button>
              )}
              {isUserCanRevertOrdersHistory && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm me-1 p-1"
                  onClick={(e) => handleRevertTradeHistory(e, row)}
                >
                  <FontAwesomeIcon icon={faUndo} className="header-icon text-white" />
                </button>
              )}
            </>
          )
          : <span>-</span>
      ),
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Action'),
    },
  ];

  return columns;
};
