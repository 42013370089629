import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClone,
  faCircleInfo,
  faTimes,
  faComment,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import moment from 'moment';
import { RangeModalDebounceFilter } from '../../pages/Transactions/modalFilters';
import { ModalFilters } from '../../components';
import { dateOptions, omitColumn, setTransactionActionColumn } from '../helper';
import { getRateInUsdt } from '../../helpers/getRateInUSD';

import styles from './styles.module.css';

export const PendingWithdrawsSchema = (
  amountRange,
  userIdFilter,
  setUserIdFilter,
  transactionIdFilter,
  setTransactionIdFilter,
  setAmountRange,
  currencyFilter,
  setCurrencyFilter,
  currencies,
  crmUsers,
  assignedToFilter,
  setAssignedToFilter,
  setTransactionFilter,
  transactionFilter,
  statusFilter,
  setStatusFilter,
  handleInfoModalToggle,
  handleCommentModalToggle,
  activeInfo,
  activeComment,
  setActiveInfo,
  setActiveComment,
  permissionName,
  resolveCurrentWithDrawTransaction,
  handleRevertTransaction,
  updateIsReal,
  columnConfig,
  isRealFilter,
  setIsRealFilter,
  queryFilter,
  setQueryFilter,
  setTimeInitiatedFilter,
  handleDeleteTransaction,
  isDeleted,
  timeInitiatedFilter,
  setDebounceAmountRange,
  inputFieldError, 
  setInputFieldError,
  brandsFilters,
  setBrandsFilters,
) => {
  const dispatch = useDispatch();
  const currencyRates = useSelector((state) => state.currencyRates);
  const brands = useSelector((state) => state.brands);

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const handleValidationInputs = ({ target: { value } }, nameField) => {
    const errorMessage = 'Must be more than 3 symbols';
    setInputFieldError((prev) => ((value.length < 3 && value.length > 0) ? { ...prev, errorMessage, [nameField]: true } : { ...prev, errorMessage: '', [nameField]: false }));
    if (nameField === 'userIdIsNotValid') setUserIdFilter(value);
  };

  const isUserCanSeeClientCard = permissionName.includes('clients');

  const columns = [
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des p-2 pl-0 ${styles.wrapperInput}`}>
          Transaction ID
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Transaction ID"
            style={inputFieldError.transactionIdIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            value={transactionIdFilter ?? ''}
            onChange={({ target: { value } }) => setTransactionIdFilter(value)}
          />
          <span className={styles.errorWrapper} />
        </div>),
      cell: ({ customId }) => (
        <CopyToClipboard text={customId}>
          <span>
            {customId}
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
      omit: omitColumn(columnConfig, 'Transaction ID'),
      minWidth: '200px',
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des p-2 pl-0 ${styles.wrapperInput}`}>
          Client ID
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Client ID"
            style={inputFieldError.userIdIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            value={userIdFilter ?? ''}
            onChange={(e) => handleValidationInputs(e, 'userIdIsNotValid')}
          />
          <span className={styles.errorWrapper}>
            {inputFieldError.userIdIsNotValid && (
            <p className={styles.errorInput}>
              {inputFieldError.errorMessage}
            </p>
            )}
          </span> 
        </div>),
      cell: ({ user }) => (
        <CopyToClipboard text={user?.customId}>
          <span>
            {user?.customId}
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
      omit: omitColumn(columnConfig, 'Client ID'),
      minWidth: '200px',
    },
    {
      name: (
        <RangeModalDebounceFilter 
          columnName="Amount" 
          value={amountRange ?? [0, 1000000]} 
          setValue={setAmountRange}
          filters={{}}
          setFilters={setDebounceAmountRange}
          filedName="amountRange"
          debounceAmountRange
        />
      ),
      minWidth: '200px',
      cell: ({ amount }) => amount,
      omit: omitColumn(columnConfig, 'Amount'),
    },
    {
      name: (
        <ModalFilters
          data={currencies}
          filters={currencyFilter}
          setFilters={setCurrencyFilter}
          tabName="Currency" 
          searchParams={{ id: 'symbol', params: ['symbol'], optionsName: ['symbol'] }}
          usersFilters={queryFilter}
          setUserFilters={setQueryFilter}
        />
      ),
      minWidth: '200px',
      cell: ({ currency }) => currency,
      omit: omitColumn(columnConfig, 'Currency'),
    },
    {
      name: (
        <ModalFilters 
          data={brands} 
          filters={brandsFilters} 
          setFilters={setBrandsFilters} 
          tabName="Brand" 
          searchParams={{ id: '_id', params: ['name', '_id'], optionsName: ['name'] }}
          usersFilters={queryFilter}
          setUserFilters={setQueryFilter}
        />
      ),
      omit: omitColumn(columnConfig, 'Brand'), 
      minWidth: '160px',
      selector: ({ brand }) => brand?.name ?? '',
    },
    {
      name: 'Client',
      cell: ({ user }) => (
        isUserCanSeeClientCard ? (
          <Link to={`/user-detail/${user._id}`}>
            <span className="full-name-text">
              {`${user.firstName} ${user.lastName}`}
            </span>
          </Link>
        ) : (
          <span className="full-name-text">
            {`${user.firstName} ${user.lastName}`}
          </span>
        )
      ),
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Client'),
    },
    {
      name: (
        <ModalFilters
          data={crmUsers}
          filters={assignedToFilter}
          setFilters={setAssignedToFilter}
          tabName="Manager"
          searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
          usersFilters={queryFilter}
          setUserFilters={setQueryFilter}
        />
      ),
      minWidth: '200px',
      cell: ({ user }) => {
        if (
          Object.hasOwnProperty.call(user, 'assignedTo')
              && Object.keys(user?.assignedTo)?.length > 0
        ) {
          return (
            <Link
              to={`/edit-admin/${user?.assignedTo?._id}`}
              className="text-decoration-none"
            >
              {`${user?.assignedTo?.firstName} ${user?.assignedTo?.lastName}`}
            </Link>
          );
        }
        return '-';
      },
      omit: omitColumn(columnConfig, 'Manager'),
    },
    {
      name: 'In USD',
      cell: ({ currency, amount }) => { 
        const usdtValue = getRateInUsdt(currency, amount, currencyRates);
        return usdtValue ? usdtValue?.toFixed(2) : '-';
      },
      omit: omitColumn(columnConfig, 'In USD'),
      minWidth: '200px',
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Crypto', _id: 'Crypto' }, { name: 'Card', _id: 'Card' }, { name: 'Bank', _id: 'Bank' }, { name: 'Admin Transaction', _id: 'Admin Transaction' }]}
          filters={transactionFilter}
          setFilters={setTransactionFilter}
          tabName="Transaction Type" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={queryFilter}
          setUserFilters={setQueryFilter}
        />
      ),
      minWidth: '230px',
      cell: ({ type }) => type || 'Admin Withdrawal',
      omit: omitColumn(columnConfig, 'Transaction Type'),
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Pending', _id: '0' }, { name: 'Completed', _id: '1' }, { name: 'Declined', _id: '2' }]}
          filters={statusFilter}
          setFilters={setStatusFilter}
          tabName="Status" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={queryFilter}
          setUserFilters={setQueryFilter}
        />
      ),
      cell: ({
        _id, isResolved, toAddress, toCard, toIban, toAccountNumber, toSwiftCode, type, comment,
      }) => (
        <>
          {isResolved === 0 ? (
            <span className="badge rounded-pill bg-warning">Pending</span>
          ) : isResolved === 1 ? (
            <span className="badge rounded-pill bg-success">Completed</span>
          ) : (
            <span className="badge rounded-pill bg-danger">Declined</span>
          )}
          <span>
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="ms-2"
              size="lg"
              color="#6E7F95"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleInfoModalToggle(_id);
              }}
            />

            {activeInfo === _id ? (
              <div className="withdrawsInfoModal">
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  color="#6E7F95"
                  style={{
                    cursor: 'pointer', position: 'absolute', right: '10px', top: '10px',
                  }}
                  onClick={() => {
                    setActiveInfo(null);
                  }}
                />

                {toAddress ? (
                  <>
                    <h6>To Address</h6>
                    <p>{toAddress}</p>
                  </>
                ) : (
                  null
                )}
                {toCard ? (
                  <>
                    <h6>To Card</h6>
                    <p>{toCard}</p>
                  </>
                ) : (
                  null
                )}
                {toIban ? (
                  <>
                    <h6>To Iban</h6>
                    <p>{toIban}</p>

                    <h6>Account Number</h6>
                    <p>{toAccountNumber}</p>

                    <h6>Swift Code</h6>
                    <p>{toSwiftCode}</p>
                  </>
                ) : (
                  null
                )}
                {
                  type === 'Admin Transaction' ? (
                    <h6>Admin Transaction</h6>
                  ) : null
                }
              </div>
            ) : (
              null
            )}
          </span>

          <span>
            <FontAwesomeIcon
              icon={faComment}
              className="ms-2"
              size="lg"
              color="#6E7F95"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleCommentModalToggle(_id);
              }}
            />

            {activeComment === _id && (
            <div className="depositsAdditionalInfoModal">
              <h5>Comment</h5>
              <FontAwesomeIcon
                icon={faTimes}
                size="lg"
                color="#6E7F95"
                onClick={() => setActiveComment(null)}
              />
              <div className="depositsAdditionalCommentModalIn">
                {comment && comment.length ? comment : 'No comments'}
              </div>
            </div>
            )}
          </span>

        </>
      ),
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Status'),
    },
    {
      name: (
        <ModalFilters 
          data={dateOptions} 
          filters={timeInitiatedFilter} 
          setFilters={setTimeInitiatedFilter} 
          tabName="Time Initiated" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={queryFilter}
          setUserFilters={setQueryFilter}
          isDateOptions
        />
      ),
      selector: ({ createdAt }) => (createdAt ? moment(createdAt).format('YYYY-MM-DD HH:mm:ss') : ''),
      omit: omitColumn(columnConfig, 'Time Initiated'),
      minWidth: '230px',
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Real', _id: 'true' }, { name: 'Fake', _id: 'false' }]}
          filters={isRealFilter}
          setFilters={setIsRealFilter}
          tabName="Real/Fake" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={queryFilter}
          setUserFilters={setQueryFilter}
        />
      ),
      minWidth: '160px',
      omit: omitColumn(columnConfig, 'Real/Fake'),
      selector: ({ isReal }) => (isReal !== undefined ? (isReal === true ? <span className="clr-green">Real</span> : <span className="clr-red">Fake</span>) : '-'),      
    },
    {
      name: 'Action(s)',
      minWidth: '300px',
      omit: omitColumn(columnConfig, 'Action'),
      cell: (row) => setTransactionActionColumn(
        row, 
        permissionName,
        resolveCurrentWithDrawTransaction,
        updateIsReal,
        handleRevertTransaction,
        handleDeleteTransaction,
        isDeleted,
        dispatch,
      ), 
    },
  ];

  return columns;
};

